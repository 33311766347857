import React from 'react';
// import { useLocation } from 'react-router-dom';

function Footer() {

  return (
    <footer className='App-footer'>
      <p className ="copyright">&copy; 2024 David Pham. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
